:global {
    .rc-slider {
      position: relative;
      height: getSpacingValues(4);
      @include padding(2, 0);

      width: 100%;
      border-radius: 6px;
      -ms-touch-action: none;
          touch-action: none;
      box-sizing: border-box;
      -webkit-tap-highlight-color: getNeutralColors(blackGradients);
    }

    .rc-slider * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: getNeutralColors(blackGradients);
    }

    .rc-slider-rail {
      position: absolute;
      width: 100%;
      background-color: getNeutralColors(blackDisabled);
      height: 4px;
      border-radius: 6px;
    }

    .rc-slider-track {
      position: absolute;
      left: 0;
      height: 4px;
      border-radius: 6px;
      background-color: getTextColors(primaryBaseText);
    }

    .rc-slider-handle {
      position: absolute;
      margin-top: - getSpacingValues(1.25);
      width: getSpacingValues(3);
      height: getSpacingValues(3);
      cursor: pointer;
      cursor: -webkit-grab;
      cursor: grab;
      border-radius: 50%;
      border: solid 2px getTextColors(primaryBaseText);
      background-color: getNeutralColors(white);
      -ms-touch-action: pan-x;
          touch-action: pan-x;
    }

    .rc-slider-handle:hover {
      border-color: getTextColors(primaryBaseText);
    }

    .rc-slider-handle:active {
      border-color: getTextColors(primaryBaseText);
      box-shadow: getShadows(level2);
      cursor: -webkit-grabbing;
      cursor: grabbing;
    }

    .rc-slider-handle:focus {
      border-color: getBrandColor(primary);
      box-shadow: 0 0 0 5px getBrandColor(primary);
      outline: none;
    }

    .rc-slider-mark {
      position: absolute;
      top: 18px;
      left: 0;
      width: 100%;
      font-size: 12px;
    }

    .rc-slider-mark-text {
      position: absolute;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      cursor: pointer;
      color: getNeutralColors(blackText);
    }

    .rc-slider-mark-text-active {
      color: getNeutralColors(blackSecondaryText)
    }

    .rc-slider-step {
      position: absolute;
      width: 100%;
      height: 4px;
      background: transparent;
    }

    .rc-slider-dot {
      position: absolute;
      bottom: -2px;
      margin-left: -4px;
      width: 8px;
      height: 8px;
      border: 2px solid getNeutralColors(blackDividers);
      background-color: getNeutralColors(white);
      cursor: pointer;
      border-radius: 50%;
      vertical-align: middle;
    }

    .rc-slider-dot:first-child {
      margin-left: -4px;
    }

    .rc-slider-dot:last-child {
      margin-left: -4px;
    }

    .rc-slider-dot-active {
      border-color: getNeutralColors(blackDividers);
    }

    .rc-slider-disabled {
      background-color: getNeutralColors(blackDividers);
    }

    .rc-slider-disabled .rc-slider-track {
      background-color: getNeutralColors(background);
    }

    .rc-slider-disabled .rc-slider-handle,
    .rc-slider-disabled .rc-slider-dot {
      border-color: getNeutralColors(background);
      box-shadow: none;
      background-color: getNeutralColors(white);
      cursor: not-allowed;
    }

    .rc-slider-disabled .rc-slider-mark-text,
    .rc-slider-disabled .rc-slider-dot {
      cursor: not-allowed !important;
    }

    .rc-slider-vertical {
      width: 14px;
      height: 100%;
      padding: 0 5px;
    }

    .rc-slider-vertical .rc-slider-rail {
      height: 100%;
      width: 4px;
    }

    .rc-slider-vertical .rc-slider-track {
      left: 5px;
      bottom: 0;
      width: 4px;
    }

    .rc-slider-vertical .rc-slider-handle {
      margin-left: -5px;
      margin-bottom: -7px;
      -ms-touch-action: pan-y;
          touch-action: pan-y;
    }

    .rc-slider-vertical .rc-slider-mark {
      top: 0;
      left: 18px;
      height: 100%;
    }

    .rc-slider-vertical .rc-slider-step {
      height: 100%;
      width: 4px;
    }

    .rc-slider-vertical .rc-slider-dot {
      left: 2px;
      margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:first-child {
      margin-bottom: -4px;
    }

    .rc-slider-vertical .rc-slider-dot:last-child {
      margin-bottom: -4px;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
      -webkit-animation-duration: .3s;
              animation-duration: .3s;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
      display: block !important;
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }

    .rc-slider-tooltip-zoom-down-leave {
      -webkit-animation-duration: .3s;
              animation-duration: .3s;
      -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
      display: block !important;
      -webkit-animation-play-state: paused;
              animation-play-state: paused;
    }

    .rc-slider-tooltip-zoom-down-enter.rc-slider-tooltip-zoom-down-enter-active,
    .rc-slider-tooltip-zoom-down-appear.rc-slider-tooltip-zoom-down-appear-active {
      -webkit-animation-name: rcSliderTooltipZoomDownIn;
              animation-name: rcSliderTooltipZoomDownIn;
      -webkit-animation-play-state: running;
              animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-leave.rc-slider-tooltip-zoom-down-leave-active {
      -webkit-animation-name: rcSliderTooltipZoomDownOut;
              animation-name: rcSliderTooltipZoomDownOut;
      -webkit-animation-play-state: running;
              animation-play-state: running;
    }

    .rc-slider-tooltip-zoom-down-enter,
    .rc-slider-tooltip-zoom-down-appear {
      -webkit-transform: scale(0, 0);
              transform: scale(0, 0);
      -webkit-animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
              animation-timing-function: cubic-bezier(0.23, 1, 0.32, 1);
    }

    .rc-slider-tooltip-zoom-down-leave {
      -webkit-animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
              animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    }
    @-webkit-keyframes rcSliderTooltipZoomDownIn {
      0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
      }

      100% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
      }
    }
    @keyframes rcSliderTooltipZoomDownIn {
      0% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
      }

      100% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
      }
    }
    @-webkit-keyframes rcSliderTooltipZoomDownOut {
      0% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
      }

      100% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
      }
    }
    @keyframes rcSliderTooltipZoomDownOut {
      0% {
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(1, 1);
                transform: scale(1, 1);
      }

      100% {
        opacity: 0;
        -webkit-transform-origin: 50% 100%;
                transform-origin: 50% 100%;
        -webkit-transform: scale(0, 0);
                transform: scale(0, 0);
      }
    }

    .rc-slider-tooltip {
      position: absolute;
      left: -9999px;
      top: -9999px;
      visibility: visible;
      box-sizing: border-box;
      -webkit-tap-highlight-color: getNeutralColors(blackGradients);
    }

    .rc-slider-tooltip * {
      box-sizing: border-box;
      -webkit-tap-highlight-color: getNeutralColors(blackGradients);
    }

    .rc-slider-tooltip-hidden {
      display: none;
    }

    .rc-slider-tooltip-placement-top {
      padding: 4px 0 8px;
    }

    .rc-slider-tooltip-inner {
      padding: 6px 2px;
      min-width: 24px;
      height: 24px;
      font-size: 12px;
      line-height: 1;
      color: getNeutralColors(white);
      text-align: center;
      text-decoration: none;
      background-color: getNeutralColors(background);
      border-radius: 6px;
      box-shadow: getShadows(level2);
    }

    .rc-slider-tooltip-arrow {
      position: absolute;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }

    .rc-slider-tooltip-placement-top .rc-slider-tooltip-arrow {
      bottom: 4px;
      left: 50%;
      margin-left: -4px;
      border-width: 4px 4px 0;
      border-top-color: getNeutralColors(blackDividers);
    }
}